var defaults = {
  thresholdCollapse: 0,
  thresholdExpand: 0,
  expandOnScrollUp: true,
};

var Scrollapse = function(el, options)
{
  this.el = el;
  this.options = Object.assign({}, defaults, options || {});

  Scrollapse.instances.push(this);

  this.init();
}

// =Static
// =============================================================================

Scrollapse.instances = [];

// =Prototype
// =============================================================================

Scrollapse.prototype.init = function()
{
  if (this.options.thresholdCollapse && !this.options.thresholdExpand) {
    this.options.thresholdExpand = this.options.thresholdCollapse;
  } else if (this.options.thresholdExpand && !this.options.thresholdCollapse) {
    this.options.thresholdCollapse = this.options.thresholdExpand;
  }

  this.isResizing = false;
  this.isStart = false;
  this.isCollapsed = false;

  this.resize();
};

Scrollapse.prototype.resize = function(windowWidth, windowHeight, scrollPos)
{
  windowWidth = windowWidth || window.innerWidth;
  windowHeight = windowHeight || window.innerHeight;
  scrollPos = scrollPos || window.scrollY;

  if (!this.isResizing)
  {
    this.isResizing = true;

    if ((this.options.thresholdCollapse < 1)) {
      this.thresholdCollapse = windowHeight * this.options.thresholdCollapse;
    }

    if ((this.options.thresholdExpand < 1)) {
      this.thresholdExpand = windowHeight * this.options.thresholdExpand;
    }

    this.reposition(scrollPos);
  }

  this.isResizing = false;
};

Scrollapse.prototype.reposition = function(scrollPos, scrollDir)
{
  scrollDir = scrollDir || 'down';

  if (scrollPos > this.thresholdCollapse) {
    this.isStart = false;
    this.el.classList.remove('is-start');
  }

  else if (scrollPos <= this.thresholdCollapse) {
    this.isStart = true;
    this.el.classList.add('is-start');
  }

  if (!this.isCollapsed && scrollDir == 'down'
    && scrollPos > this.thresholdCollapse)
  {
    this.collapse();
  }

  else if (this.isCollapsed
    && ((this.options.expandOnScrollUp && scrollDir == 'up')
      || (this.thresholdExapnd && scrollPos < this.thresholdExpand)))
  {
    this.expand();
  }
};

Scrollapse.prototype.collapse = function()
{
  if (this.isCollapsed) return;

  this.el.classList.add('is-collapsed');
  this.el.classList.remove('is-expanded');
  this.isCollapsed = true;
};

Scrollapse.prototype.expand = function()
{
  if (!this.isCollapsed) return;

  this.el.classList.add('is-expanded');
  this.el.classList.remove('is-collapsed');
  this.isCollapsed = false;
};

// =Events
// =============================================================================

var scrollTicking = false;
var lastScrollPos = 0;
var scrollPos = lastScrollPos;
var scrollDir = null;

var resizeTicking = false;
var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;

document.addEventListener('scroll', function(e)
{
  if (!scrollTicking)
  {
    window.requestAnimationFrame(function()
    {
      lastScrollPos = scrollPos;
      scrollPos = window.scrollY;
      scrollDir = (scrollPos > lastScrollPos ? 'down' : 'up');

      Scrollapse.instances.forEach(function(scrollapse) {
        scrollapse.reposition(scrollPos, scrollDir);
      });

      scrollTicking = false;
    });

    scrollTicking = true;
  }
});

window.addEventListener('resize', function()
{
  if (!resizeTicking)
  {
    window.requestAnimationFrame(function()
    {
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;

      Scrollapse.instances.forEach(function(scrollapse) {
        console.log('resize scrollapse', scrollapse.el);
        scrollapse.resize(windowWidth, windowHeight, scrollPos);
      });
      resizeTicking = false;
    });

    resizeTicking = true;
  }
});

// =INIT
// =============================================================================

function initScrollapses()
{
  var scrollapses = document.querySelectorAll('.js-scrollapse');

  scrollapses.forEach(function(scrollapse) {
    var options = JSON.parse(scrollapse.getAttribute('data-scrollapse') || '{}');
    new Scrollapse(scrollapse);
  });
}

initScrollapses();

// =EXPORTS
// =============================================================================

export default Scrollapse;
